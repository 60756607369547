import React from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";

const Table = ({ medianReport }) => {
  const tableData = medianReport[0];

  return (
    <div className="table-container">

      <table className="data-table">
        <thead>
          <tr>
            <th>Parameter</th>
            <th>Average</th>
            <th>Minimum</th>
            <th>Maximum</th>
            <th>Median</th>
            <th>Mode</th>
            <th>Deviation</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(tableData).map(([parameter, values]) => {
            return (
              <tr key={parameter}>
                <td>{parameter}</td>
                <td>{values.average}</td>
                <td>{values.min}</td>
                <td>{values.max}</td>
                <td>{values.median}</td>
                <td>{values.mode}</td>
                <td>{values.deviation}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;


